import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {UserManagementService} from '../../../shared/services/user-management-service/user-management.service';
import {modules} from '../modules';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserManagementResolverService  {

    constructor(
        private userManagementService: UserManagementService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        if (environment.ENVIRONMENT === 'local') {
            return this.setMappedModulesForLocalEnvironment();
        }

        return this.userManagementService.getModules().pipe(
            map(userManagementModules => {
                return modules.map(dashboardModule => {
                    userManagementModules.forEach(userManagementModule => {
                        if (userManagementModule.identifier === dashboardModule.id) {
                            dashboardModule.canRead = true;
                        }

                        if (userManagementModule.rules) {
                            userManagementModule.rules.forEach(rule => {
                                dashboardModule.modules?.forEach(dashboardSubmodule => {
                                    if (rule.identifier === dashboardSubmodule.id) {
                                        dashboardSubmodule.canRead = true;
                                    }

                                    // if (userManagementModule.rules) {
                                    //     userManagementModule.rules.forEach(rule => {
                                    (dashboardSubmodule as any).modules?.forEach(dashboardSubsubmodule => {
                                        // if (rule.identifier === dashboardSubmodule.id) {
                                        dashboardSubsubmodule.canRead = true;
                                        // }
                                    });
                                    //     })
                                    // }
                                });
                            });
                        }
                    });

                    return dashboardModule;
                });
            }),
            tap(mappedModules => {
                this.userManagementService.setModules(mappedModules);
            })
        );
    }

    private setMappedModulesForLocalEnvironment(): Observable<any> {
        const mappedModules = modules.map(dashboardModule => {
            dashboardModule.canRead = true;

            dashboardModule.modules?.forEach(dashboardSubmodule => {
                dashboardSubmodule.canRead = true;

                (dashboardSubmodule as any).modules?.forEach(dashboardSubSubmodule => {
                    dashboardSubSubmodule.canRead = true;
                });
            });

            return dashboardModule;
        });

        this.userManagementService.setModules(mappedModules);

        return of(mappedModules);
    }
}
