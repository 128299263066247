import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageComponent} from './components/page/page.component';
import {CoreModule} from '../core/core.module';
import {PageContentDirective} from './page-content.directive';
import {ListPageComponent} from './components/list-page/list-page.component';
import {ObjectValuesPipe} from './object-values.pipe';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [
        PageComponent,
        ListPageComponent,
        PageContentDirective,
        ObjectValuesPipe
    ],
    imports: [
        CommonModule,
        CoreModule,
        SharedModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {
}
