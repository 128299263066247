import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-scheduled-maintenance',
    templateUrl: './scheduled-maintenance.component.html',
    styleUrls: ['./scheduled-maintenance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledMaintenanceComponent {

}
