import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PlatformService} from '../../../shared/services/platform-service/platform.service';
import {OrganisationService} from '../../../shared/services/organisation-service/organisation.service';
import {HttpParams} from '@angular/common/http';
import {
    OrganisationManagementService
} from '../../../shared/services/organisation-service/organisation-management.service';

@Injectable({
    providedIn: 'root'
})
export class AppResolverService {
    private platformService: PlatformService;
    private organisationService: OrganisationService;
    private organisationManagementService: OrganisationManagementService;

    constructor(private injector: Injector) {
        this.platformService = this.injector.get(PlatformService);
        this.organisationService = this.injector.get(OrganisationService);
        this.organisationManagementService = this.injector.get(OrganisationManagementService);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const technicalLinkId = this.platformService.getTechnicalLinkId();
        this.organisationManagementService.syncItem(technicalLinkId);

        return this.organisationService.getOrganisation(technicalLinkId, new HttpParams().set('depth', '2').set('organisation[organisationSettings]', 'true'));
    }
}

