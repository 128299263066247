<router-outlet></router-outlet>

<app-detail-sidebar [class.is-open]="showSidebar" (close)="closeSidebar()">
    <router-outlet name="detail" (activate)="showSidebar = true; checkCloseAfterRefresh($event)"
                   (deactivate)="showSidebar = false;"></router-outlet>
</app-detail-sidebar>

<app-detail-overlay [class.is-open]="showOverlay" (close)="closeOverlay()">
    <router-outlet name="overlay" (activate)="showOverlay = true"
                   (deactivate)="showOverlay = false;"></router-outlet>
</app-detail-overlay>
