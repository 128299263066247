import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {NotificationService} from '../sse/notification.service';

type MercureToken = {
    organisationAccountId: string;
    token: string;
};

@Injectable({
    providedIn: 'root'
})
export class MercureService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient, private notificationService: NotificationService) {
    }

    authenticate(): void {
        this.http.get<MercureToken>(this.baseUrl + '/v1.0/notifications/authorization', {
            withCredentials: true,
            observe: 'response'
        }).pipe(map((response: HttpResponse<MercureToken>) => {
            return response.body;
        })).subscribe((token: MercureToken) => {
            this.notificationService.subscribeTo(['notifications/' + token.organisationAccountId], token.token);
        });
    }
}
