import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {NotificationService} from './notification-service/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import {CmTranslationService} from '../../core/services/cm-translation.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    private notificationService: NotificationService;
    private translationService: CmTranslationService;

    private skipErrorMessages = [
        `Cannot read properties of undefined (reading 'offsetHeight')`,
        `Cannot read properties of null (reading '_moveFromOutside')`,
        `Cannot read properties of undefined (reading 'getBoundingClientRect')`
    ];

    errorCount = 0;

    constructor(private injector: Injector) {
        this.notificationService = this.injector.get(NotificationService);
        this.translationService = this.injector.get(CmTranslationService);

        const context = this;
        setInterval(() => {
            context.errorCount = 0;
        }, 5000);
    }

    handleError(error) {
        if (environment.ENVIRONMENT === 'local') {
            console.error(error);
        } else {
            const prettyError = this.generatePrettyError(error);
            console.error(prettyError);
        }

        if (error instanceof HttpErrorResponse && !navigator.onLine) {
            // Handle offline error
            return this.showTranslatedErrorNotification('No_Internet_Connection');
        } else if (error.error && error.error.code) {
            this.showTranslatedErrorNotification(error.error.code);
        } else if (error.message === 'Unable to establish connection with QZ') {
            return;
        } else if (this.skipErrorMessages.includes(error.message)) {
            //
        } else {
            this.errorCount++;

            if (this.errorCount <= 1) {
                this.showTranslatedErrorNotification('Default_Error');
            }
        }
    }

    showTranslatedErrorNotification(errorCode: any) {
        const key = `Error.${errorCode}`;
        let phrase = this.translationService.getPhraseForLanguage(key);

        if (!phrase) {
            phrase = this.translationService.getPhraseForLanguage('Error.Default_Error');
        }

        this.notificationService.error(phrase);
    }

    private generatePrettyError(error) {
        let response = `Oops! Something went wrong. If you want to report this error, please provide the following information:`;

        if (error.error) {
            response = `
${response}

Error: \t\t\t ${error.message}
Error Code: \t ${error.error.code}
Time: \t\t ${new Date().toISOString()}
Message: \t\t ${error.error.message}
Details: \t\t ${error.error.detail}`;
        }

        if (error.error && error.error.debug) {
            response = `${response}
Debug Information: \t ${error.error.debug.file}:${error.error.debug.line}`;
        }

        if (error.url) {
            response = `${response}
URL: \t\t\t\t ${error.url}`;
        }

        if (error.request) {
            response = `${response}

HTTP Request:
${btoa(JSON.stringify(error.request))}`;
        }

        if (error.stack) {
            response = `${response}

${error.stack}`;
        }

        return response;
    }

}
