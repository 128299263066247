<cm-image data-type="underConstruction" data-min-height="600"></cm-image>

<div class="row">
    <div class="col-12 offset-sm-6 col-sm-6">
        <div class="card">
            <h2 class="title">{{('Status.Scheduled_Maintenance.Title' | trans)}}</h2>
            <p class="message">{{('Status.Scheduled_Maintenance.Body' | trans)}}</p>
            <div class="links">
                <a href="https://status.cm.com/" target="_blank">CM.com Status Page</a><br>
                <a href="https://www.cm.com/help/" target="_blank">Help Center</a>
            </div>
        </div>
    </div>
</div>
