import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {fromEvent, Observable, of} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';
import {PlatformService} from '../../../shared/services/platform-service/platform.service';

@Injectable({
    providedIn: 'root'
})
export class PlatformHeaderResolverService  {

    constructor(private platformService: PlatformService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const header = document.querySelector('platform-header');

        const headerLoaded$ = fromEvent(header, 'loaded').pipe(
            first(),
            map((platformLoadedEvent: CustomEvent) => platformLoadedEvent.detail),
            tap(details => {
                this.platformService.setAccount(details.Account);

                const technicalLinks = details.Account ? details.Account.TechnicalLinks : [];
                const technicalLink = this.getTicketingTechnicalLinkId(technicalLinks);

                this.platformService.setTechnicalLinkId(technicalLink);
            })
        );

        if (header && header.classList.contains('platform-loaded')) {
            return of({
                Account: (window as any).getCmAccount(),
                SSO: (window as any).getCmSSO(),
                Translations: null
            }).pipe(
                first(),
                tap(details => {
                    this.platformService.setAccount(details.Account);

                    const technicalLinks = details.Account ? details.Account.TechnicalLinks : [];
                    const technicalLink = this.getTicketingTechnicalLinkId(technicalLinks);

                    this.platformService.setTechnicalLinkId(technicalLink);
                })
            );
        }

        return headerLoaded$;
    }

    private getTicketingTechnicalLinkId(technicalLinks): string {
        return technicalLinks.find(technicalLink => technicalLink.TechnicalLinkTypeID === 'TICKETING')?.ID;
    }
}
