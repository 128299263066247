import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './status/components/not-found/not-found.component';
import {
    AnalyticsDashboardComponent
} from './shared/components/analytics/analytics-dashboard/analytics-dashboard.component';
import {PageWrapperComponent} from './shared/components/page-wrapper/page-wrapper.component';
import {InitialiseResolverService} from './core/routing/resolvers/initialise-resolver.service';
import {UnauthorizedComponent} from './status/components/unauthorized/unauthorized.component';
import {SelectAccountComponent} from './core/components/select-account/select-account.component';
import {NewWindowGuard} from './core/routing/guards/new-window.guard';
import {MoveOrderSidebarComponent} from './shared/components/move-order-sidebar/move-order-sidebar.component';
import {MoveOrderSeatmapComponent} from './shared/components/move-order-seatmap/move-order-seatmap.component';
import {OrderDetailSidebarComponent} from './shared/components/order-detail-sidebar/order-detail-sidebar.component';
import {PaymentListComponent} from './shared/components/payment-list/payment-list.component';
import {NavigationAllowedResolverService} from './core/routing/resolvers/navigation-allowed-resolver.service';
import {MoveRequestSidebarComponent} from './shared/components/move-request-sidebar/move-request-sidebar.component';
import {ScheduledMaintenanceComponent} from './status/components/scheduled-maintenance/scheduled-maintenance.component';
import {MaintenanceResolverService} from './core/routing/resolvers/maintenance-resolver.service';

const routes: Routes = [
    {
        path: '',
        component: PageWrapperComponent,
        canActivateChild: [NewWindowGuard],
        resolve: {
            init: InitialiseResolverService
        },
        children: [
            {
                path: 'dashboard',
                component: AnalyticsDashboardComponent
            },
            {
                path: '',
                redirectTo: 'events',
                pathMatch: 'full'
            },
            {
                path: 'custom-reports',
                redirectTo: 'reports/custom',
                pathMatch: 'full'
            },
            {
                path: 'events',
                loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
            },
            {
                path: 'subscription-types',
                loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
            },
            {
                path: 'orders',
                loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
            },
            {
                path: 'customers',
                loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
            },
            {
                path: 'shops',
                loadChildren: () => import('./shops/shops.module').then(m => m.ShopsModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'requests',
                loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'employee',
                loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
            },
            {
                path: 'status/unauthorized',
                component: UnauthorizedComponent
            },
            {
                path: 'status/not-found',
                component: NotFoundComponent
            },
            {
                path: 'payments',
                component: PaymentListComponent,
                data: {
                    moduleId: 'ST.PAYMENT_LIST'
                },
                resolve: {
                    module: NavigationAllowedResolverService
                },
                children: [
                    {
                        path: '',
                        component: PaymentListComponent,
                    }
                ]
            },
            { path: '**', component: NotFoundComponent }
        ]
    },
    {
        path: 'status/scheduled-maintenance',
        component: ScheduledMaintenanceComponent,
        resolve: {
            init: MaintenanceResolverService
        }
    },
    {
        path: 'select-account',
        component: SelectAccountComponent,
    },
    {
        path: 'move-order',
        component: MoveOrderSidebarComponent,
        outlet: 'detail'
    },
    {
        path: 'orders/:orderId',
        component: OrderDetailSidebarComponent,
        outlet: 'detail'
    },
    {
        path: 'move-order-seatmap/:venueId/:venueSectionId',
        component: MoveOrderSeatmapComponent,
        outlet: 'overlay'
    },
    {
        path: 'move-request/:requestId',
        component: MoveRequestSidebarComponent,
        outlet: 'detail'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
