import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {WhereToService} from '../../../shared/services/platform-service/where-to.service';

@Injectable({
    providedIn: 'root'
})
export class NewWindowGuard  {
    public openInNewTab = false;

    constructor(private whereToService: WhereToService) {
        document.addEventListener('click', (event) => {
            if (event.metaKey || event.ctrlKey) {
                this.openInNewTab = true;

                setTimeout(() => {
                    this.openInNewTab = false;
                }, 200);

                return;
            }
        });
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return new Promise((resolve) => {
            setTimeout(() => {
                if (this.openInNewTab) {
                    const url = window.location.origin + this.whereToService.getBaseUrl() + state.url;
                    window.open(url, '_blank');
                }

                resolve(!this.openInNewTab);
            });
        });
    }

}
