import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {MaintenanceModeService} from '../../../shared/services/maintenance-mode.service';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceResolverService  {
    private maintenanceModeService: MaintenanceModeService;

    constructor(private injector: Injector, private router: Router) {
        this.maintenanceModeService = this.injector.get(MaintenanceModeService);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        this.maintenanceModeService.getMaintenanceStatus()
            .subscribe(
                maintenanceActive => {
                    if (maintenanceActive === false) {
                        return void this.router.navigate(['/events']);
                    }
                }
            );
    }
}
