import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {SharedModule} from '../shared/shared.module';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {RouterModule} from '@angular/router';
import {CoreModule} from '../core/core.module';
import {CommonModule} from '@angular/common';
import {ScheduledMaintenanceComponent} from './components/scheduled-maintenance/scheduled-maintenance.component';

@NgModule({
    declarations: [UnauthorizedComponent, NotFoundComponent, ScheduledMaintenanceComponent],
    imports: [SharedModule, RouterModule, CoreModule, CommonModule],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StatusModule {
}
