import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SidebarService} from '../../../shared/services/sidebar/sidebar.service';

@Component({
    selector: 'app-detail-sidebar',
    templateUrl: './detail-sidebar.component.html',
    styleUrls: ['./detail-sidebar.component.scss']
})
export class DetailSidebarComponent implements OnInit {

    @Input() isOpen = false;

    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    private host: any;

    constructor(private elementRef: ElementRef, private sidebarService: SidebarService) {
    }

    ngOnInit(): void {
        this.host = this.elementRef.nativeElement;
    }

    onClose(): void {
        this.isOpen = false;
        this.host.classList.remove('is-open');
        this.close.emit();
    }

}
