import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {PlatformHeaderResolverService} from './platform-header-resolver.service';
import {UserManagementResolverService} from './user-management-resolver.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AppResolverService} from './app-resolver.service';
import {PlatformService} from '../../../shared/services/platform-service/platform.service';
import {MercureService} from '../../../shared/services/mercure/mercure.service';

@Injectable({
    providedIn: 'root'
})
export class InitialiseResolverService  {

    constructor(
        protected platformHeaderResolver: PlatformHeaderResolverService,
        protected userManagementResolver: UserManagementResolverService,
        private appResolver: AppResolverService,
        private platformService: PlatformService,
        private router: Router,
        private mercureService: MercureService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.platformHeaderResolver.resolve(route, state).pipe(
            switchMap(platform => {
                if (this.platformService.getTechnicalLinkId()) {
                    return forkJoin([
                        forkJoin([
                            of(platform),
                            this.appResolver.resolve(route, state).pipe(
                                catchError(err => {
                                    return of(null);
                                })
                            )
                        ]),
                        this.userManagementResolver.resolve(route, state)
                    ]);
                }

                return forkJoin([
                    of(platform),
                ]);
            }),
            map(initialiseData => {
                return {
                    platform: initialiseData[0][0] || [],
                    organisation: initialiseData[0][1] || null,
                    // @ts-ignore
                    userManagement: initialiseData[1] || [],
                };
            }),
            switchMap(initialiseData => {

                // Select Account
                if (!this.platformService.getAccount() && state.url !== '/select-account') {
                    this.router.navigateByUrl('select-account');
                }
                // Unauthorized
                else if ((initialiseData.userManagement as Array<any>)?.length === 0 && state.url !== '/status/unauthorized') {
                    this.router.navigateByUrl('status/unauthorized');
                }

                if (this.platformService.getTechnicalLinkId()) {
                    this.mercureService.authenticate();
                }

                return of(initialiseData);
            })
        );
    }
}
